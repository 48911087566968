<template>
    <div class="LayoutHolder" id="PageTop">
        <Header/>
            <router-view />
        <Footer/>
    </div>
</template>
<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import store from '@/store';
import router from '@/router';

export default {
    name: "RootHome",
    components:{
        Header,
        Footer
    },
	computed:{
		isLoading: function(){
            return store.getters.isLoading;
		}
    },
    mounted:function(){
        this.$store.dispatch({
            type: 'getCategorieCalcisticheFromApi'
        });
		this.$store.dispatch({
			type: 'getSocietaCalcistiche'
		});
        this.$store.dispatch({
            type: 'getProvinceFromApi'
        });
    },
    beforeRouteEnter (to, from, next) {
        store.dispatch({
                type: 'getResourcesFromApi',
                token: store.getters.getToken,
            }).then(
                (data) => {
                    if(!data){
                        store.commit("deleteUtente");
                        store.commit('deleteAzienda');
                        store.commit('deleteCarrello');
                        localStorage.clear();
                        router.push({name: "Login"});
                    }
                    else{
                        next();
                    }
                }
            ); 
    }
}
</script>